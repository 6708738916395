<template>
  <div>
    <b-row>
      <b-col md="6">
        <h5 class="p-1 pb-0">
          {{ $t('navigation-menu.settings') }}
          <b-form-checkbox v-if="isADistrict"
                           v-model="$store.state.taskHelper.forceSyncSettingToSchool"
                           switch
                           class="mt-2"
          >
            Force sync applies the updated settings to all child schools, but only for enabling purposes.
          </b-form-checkbox>
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="align-items-center justify-content-between mb-1 setting-tab">
            <b-card no-body
                    style="width: 100%"
            >
              <b-tabs pills
                      card
                      vertical
                      nav-class="nav-left"
              >
                <b-tab
                  title="AI"
                  active
                  lazy
                >
                  <b-overlay :show="showOverlay">
                    <ai-settings
                      :options-local="optionsLocal"
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('labels.engagements')"
                  lazy
                >
                  <b-overlay :show="showOverlay">
                    <engagements
                      :options-local="optionsLocal"
                      :avatar-setting="isADistrict ? Number(optionsLocal?.avatar ?? 0) : Number(optionsLocal?.district?.avatar ?? 0)"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('notifications')"
                >
                  <b-overlay :show="showOverlay">
                    <notification-settings
                      :options-local="optionsLocal"
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('setting-module.camera-setting-tab')"
                >
                  <b-overlay :show="showOverlay">
                    <camera-settings
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('setting-module.other-setting-tab')"
                >
                  <b-overlay :show="showOverlay">
                    <other-settings
                      :options-local="optionsLocal"
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('setting-module.classroom-student-lab-settings-tab')"
                >
                  <b-overlay :show="showOverlay">
                    <student-lab-settings
                      :options-local="optionsLocal"
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab
                  :title="$t('setting-module.visibility-options')"
                >
                  <b-overlay :show="showOverlay">
                    <visibility-options
                      :options-local="optionsLocal"
                      :child-users="childUsers"
                      :current-user-type="generalData.usertype"
                      @toggleOverlay="(value) => showOverlay = value"
                    />
                  </b-overlay>
                </b-tab>
                <!-- <b-tab
                  v-if="generalData.usertype === 'school'"
                  title="Partner Service"
                >
                  <b-overlay :show="showOverlay">
                    <partner-setting />
                  </b-overlay>
                </b-tab> -->
              </b-tabs>
            </b-card>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BOverlay, BCol, BCard, BTabs, BTab, BFormCheckbox,
} from 'bootstrap-vue'
import {
  getUserTypeLevel,
  getChildUserTypesOfType,
} from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import AiSettings from './AiSettings.vue'
import Engagements from '../school/Engagements.vue'
import NotificationSettings from './NotificationSettings.vue'
import CameraSettings from './CameraSettings.vue'
import OtherSettings from './OtherSettings.vue'
import StudentLabSettings from './StudentLabSettings.vue'
import VisibilityOptions from './VisibilityOptions.vue'
// import PartnerSetting from './PartnerSetting.vue'

export default {
  components: {
    OtherSettings,
    CameraSettings,
    NotificationSettings,
    BFormCheckbox,
    // PartnerSetting,
    Engagements,
    AiSettings,
    StudentLabSettings,
    VisibilityOptions,
    BRow,
    BCol,
    BOverlay,
    BCard,
    BTabs,
    BTab,
  },
  data() {
    return {
      self: getUserData(),
      showOverlay: false,
      optionsLocal: {},
      generalData: [],
      childUsers: [],
    }
  },
  computed: {
    isADistrict() {
      return this.self.usertype === 'district'
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    getUserData() {
      this.isProcessing = true
      useJwt
        .userShow()
        .then(response => {
          this.generalData = response.data.data
          this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
          this.childUsers = getChildUserTypesOfType(this.generalData.usertype).map(user => ({
            text: this.$i18n.tc(this.capitalizeFirstLetter(user)),
            value: getUserTypeLevel(user),
          }))
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style>
 .setting-tab .nav {
     height: 400px !important;
 }
 .setting-tab .nav .nav-item{
   margin-bottom: 5px;
 }
</style>
